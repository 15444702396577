import { Component, Vue, Prop, Watch, InjectReactive } from "vue-property-decorator"
import { cloneDeep, tap, set, startCase } from 'lodash-es'
import { FormsMetadataResource, MaintenanceSystemsResource, SystemMediaResource, SystemMediaUploadResource } from "@/resources"
import {EntityResource, AssociationResource} from "@/resources"
import AsyncComputed from 'vue-async-computed-decorator'
import _ from "lodash"
import { State } from "ketting"

// @ts-ignore
@Component({})
export abstract class ConfigurableFormsMixin extends Vue {

  @Prop() readonly halFormName! : string
  doUpdateHook! : Function

  abstract getValue() : any

  getHalFormName() : string {
    if (!this.halFormName) {
      throw Error("Unknown form.  halFormName must be supplied as a prop, or returned from the overriden method getHalFormName().")
    }
    return this.halFormName
  }

  /**
   * Sets the new value on our model after cloning, then performing an input event with the cloned and merged object.
   * @param {*} key Key on object to update
   * @param {*} newValue new value to set
   * @param {*} isArray if the key is for an array property
   * @param {*} arrayIdx array index to remove if an array splice, undefined for add
   */
  doUpdate(key: string, newValue: any, isArray?: boolean, arrayIdx?: number) {
    this.doObjectUpdate(this.getValue(), "input", key, newValue, isArray, arrayIdx)
  }

  /**
   * Sets the new value on our model after cloning, then performing an input event with the cloned and merged object.
   * @param {*} valueObject The parent object of the property (key) to update
   * @param {*} eventName Event name to emit (e.g. 'input')
   * @param {*} key Key on value to update
   * @param {*} newValue new property value to set
   * @param {*} isArray if the key is for an array property
   * @param {*} arrayIdx array index to remove if an array splice, undefined for add
   */
   doObjectUpdate(valueObject: any, eventName : string, key: string, newValue: any, isArray?: boolean, arrayIdx?: number) {

    let clonedAndMerged
    if (!isArray) {
      clonedAndMerged = tap(cloneDeep(valueObject), v => set(v, key, newValue))
    } else {
      clonedAndMerged = arrayIdx === undefined
        ? tap(cloneDeep(valueObject), v => v[key].push(newValue))
        : tap(cloneDeep(valueObject), v => v[key].splice(arrayIdx, 1))
    }

    //console.log(this.value)
    //console.log(clonedAndMerged)

    this.$emit(eventName, clonedAndMerged)
    if (typeof this.doUpdateHook === "function") {
      this.doUpdateHook(key, newValue)
    }
  }

  doChange(key: string, value: string) {
    this.$emit('change', tap(cloneDeep(this.getValue()), v => set(v, key, value)))
  }

  halProps(fieldname: string, overrides: any = {}) {
    return this.getHalProps(this.getHalFormName(), fieldname, overrides)
  }

  getHalField(formname: string, fieldname: string) {
    return FormsMetadataResource.Instance.getHalField(formname, fieldname)
  }

  getHalOptions(formname: string, fieldname: string) {
    return FormsMetadataResource.Instance.getHalOptions(formname, fieldname)
  }

  getHalProps(formname: string, fieldname: string, overrides: any) {
    // TODO maxLength -> add counter prop ?
    const halField = { ...this.getHalField(formname, fieldname), ...overrides }
    if (!halField) return {}

    // if pattern, apply if required and we have a value to check
    /* eslint-disable no-unused-vars */
    const rules : any[] = []
    if (halField.pattern) {
      rules.push((v : any) => {
        return !v || halField.pattern.test(v) || "Invalid " + fieldname.toLowerCase()
      })
    }
    
    if (halField.minLength !== undefined) {
      // ignore minlength=0 if required is not false
      const minLengthZeroAndNotRequired = halField.minLength == 0 && !halField.required
      if (!minLengthZeroAndNotRequired) {
        rules.push((v : any) => !!v && v.length >= halField.minLength || startCase(fieldname) + ' length must be greater than ' + halField.minLength + ".")
      }
    }
    if (halField.maxLength !== undefined) {
      // note: null/empty is ok as required will catch it, allows for zero length strings
      // where required == false
      rules.push((v : any) => (!!v == false) || (v.length <= halField.maxLength) || startCase(fieldname) + ' length must be less than ' + halField.maxLength + ".")
    }
    if (halField.required) {
      rules.push((v : any) => !!v || startCase(fieldname) + ' is required.')
    }

    const options = this.getHalOptions(formname, fieldname)

    /* eslint-enable no-unused-vars */
    return {
      ...halField,
      value: _.get(this.getValue(), fieldname), // fieldname can be a path
      rules: rules,
      clearable: options.length > 0 && !halField.required,
      counter : halField.maxLength,
      items: options,
      'validate-on-blur': rules.length > 0,
      readonly: halField.readOnly,   // html attribute
      disabled: halField.readOnly,   // disable if readonly
      maxlength: halField.maxLength, // html attribute
      minlength: halField.minLength,  // html attribute
      ...overrides
    }
  }
}


/**
 * Mixin to assist in performing "instant" updates of a single field instead of updating all values of an Entity.  This
 *  is done via the resource partial update patch method.
 */
@Component({ components: {} })
export class InstantUpdateFieldMixin extends ConfigurableFormsMixin {
  @Prop({required: true}) value! : EntityResource
  @Prop({required: true}) propName! : string
  @Prop() otherValues! : any
  
  valid : boolean = true
  updating : boolean = false
  errors : string = ""
  messages : string = ""
  keyIdx : number = 0

  /**
   * Override to fetch resource data for our forms mixin.
   */
  getValue() : any {
    return this.value?.data()
  }

  getHint() : string {
    return "Press [return] to update."
  }

  reset() {
    if (!this.updating) {
      const theForm : any = this.$refs.form
      this.keyIdx++
      this.valid = theForm.validate()
      this.valid = true
    }
  }

  update(val : any) {
    const theForm : any = this.$refs.form
    this.valid = theForm.validate()

    if (this.valid) {
      this.updating = true
      this.messages = "Updating..."

      this.value.mergePatch({[this.propName] : val, ...this.otherValues}).then(() => {
        this.showMessage("Update complete.")
        this.$emit("input", this.value)
      }).catch((err) => {
        this.showError("Error updating field " + (err.status ? "(" + err.status + ")" : "") + ".")
        console.error(err)
      }).finally(() => {
        this.updating = false
        this.reset() // will reset some ui issues after udpate (e.g. overflow button)
      })
    }
  }

  showMessage(val : string) {
    this.messages = val
    setTimeout(() => {
        this.messages = ""
     }, 3000)
  }
  
  showError(val : string) {
    this.errors = val
    setTimeout(() => {
        this.errors = ""
        this.messages = ""
     }, 3000)
  }

}


@Component({})
export default class ValueBasedFormsMixin extends ConfigurableFormsMixin {

  @Prop({required: true}) readonly value : any
  getValue() : any {
    return this.value
  }
}

// @ts-ignore
@Component({})
export class ResourceFormDialogMixin extends Vue { 
  @Prop({ required : true }) readonly value!: boolean
  newModelFunction() : any {throw new Error("newModelFunction() must be implemented"); return {}}
}


// @ts-ignore
@Component({})
export class ResourceFormSystemMediaUploadMixin extends Vue { 
  systemMediaUploadResource : SystemMediaUploadResource | null = null
  mediaUploadAssociationResource : AssociationResource<SystemMediaResource[]> | null = null

  postEntityUpdate() {

    // TODO another example of async component requirements
    
    // if editing, return the media resource for us
    // @ts-ignore
    if (this.rdata && this.rdata.id) {
      // @ts-ignore
      this.mediaUploadAssociationResource = this.resource.media
    }
    else {
      // TODO errors
      new SystemMediaUploadResource().post({}).then(s => {
        this.systemMediaUploadResource = new SystemMediaUploadResource(s.data.id)
        this.mediaUploadAssociationResource = this.systemMediaUploadResource.media
      })
    }
  }

  /** 
   * If new, we move all media from the temporary upload object to us, then delete the temp.
   */
  async postSaveCallback(state : State<any>) {

    // @ts-ignore
    if (this.rdata && this.rdata.id) return Promise.resolve()

    if (this.systemMediaUploadResource == null) {
      throw new Error("systemMediaUploadResource not set ?")
    }

    // create a new resource instance to transfer objects to
    // @ts-ignore
    if (!this.newSystemMediaResource) {
      throw new Error("newSystemMediaResource() not provided ?")
    }
    // @ts-ignore
    const newHr = this.newSystemMediaResource(state.data.id)

    // fetch all media uploaded
    const medias = await this.systemMediaUploadResource.media.getAssociation({}, false)

    // assign to HR
    const uris = medias.map(m => m.uriFull)
    await newHr.media.addTo(uris)

    // delete temp container
    await this.systemMediaUploadResource.delete()

    this.systemMediaUploadResource = null
 }

}


@Component({})
export class EntityResourceList extends Vue {
  
  formDialog : boolean = false
  selectedResource : EntityResource | null = null
  deleteDialog : boolean = false
  deleting : boolean = false
  deleteError : any = null

  closeFormDialog() {
    this.formDialog = false
    this.selectedResource = null
  }


  editItem(itemResource : EntityResource) {
    this.selectedResource = itemResource
    this.formDialog = true
  }

  removeItem(itemResource : EntityResource) {
    this.selectedResource = itemResource
    this.deleteDialog = true  
  }

  @Watch("deleteDialog")
  deleteDialogChanged() {
    this.deleteError = null
  }

  doDeleteItem() {
    this.deleting = true
    this.selectedResource?.delete().then(() => {
      this.selectedResource = null
      this.deleteDialog = false
    }).catch((err) => {
      console.error(err)
      if (err?.status === 409) {
        this.deleteError = "Can not delete this item as there is another one dependent upon it.  Please delete that one first then try again. (409)"
      } else {
        this.deleteError = err
      }
    }).finally(() => {
      this.deleting = false
    })
  }
 }

 @Component({})
export class AbstractMaintenanceSystemList extends EntityResourceList {
  @InjectReactive() readonly maintenanceSystem!: MaintenanceSystemsResource

  customSortField = (er : EntityResource) => er.data().name
  customSortDirection = "asc"
  customSortCaseInsensitive = true
}

